import { useStaticQuery, graphql } from "gatsby"

export const useMenuLinks = () => {
    const data = useStaticQuery(graphql`
        query {
            datoCmsLocation(locale: {eq: "en"}, websiteUrl: {eq: "ie.mattress.zone"}) {
                compareSidebarInfoNode {
                    childMarkdownRemark {
                        html
                    }
                }
                flagImage {
                    gatsbyImageData(
                        imgixParams: {colors: 10, h: "25", w: "25", fit: "crop", auto: "format", mask: "ellipse"}
                        placeholder: BLURRED
                    )
                }
                country {
                    countryName
                }
            }
        }
    `)

    return data.datoCmsLocation
}